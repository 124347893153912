//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { CustomerAddLog } from '@/api/ceshi_xiugai2/myproject';
export default {
  components: {
    Head: Head
  },
  data: function data() {
    return {
      titled: '客户新增记录',
      tableData: [],
      page: 1,
      pagesize: 15,
      total: 0
    };
  },
  created: function created() {
    this.GetList();
  },
  methods: {
    GetList: function GetList() {
      var _this = this;
      CustomerAddLog({
        pagesize: this.pagesize,
        page: this.page
      }).then(function (respomse) {
        _this.tableData = respomse.data.data;
        _this.total = respomse.data.total;
      });
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this2 = this;
      this.page = val;
      this.$nextTick(function () {
        _this2.GetList();
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this3 = this;
      this.pagesize = val;
      this.$nextTick(function () {
        _this3.GetList();
      });
    }
  }
};